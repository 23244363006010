/*-------------------------------------------------------*/
/* Content Home
/*-------------------------------------------------------*/

/* Intro
-------------------------------------------------------*/

.intro-heading {
  font-size: 1.86rem;
  margin-bottom: 40px;
  letter-spacing: 0;
  text-align: center;
  //padding: 0 70px;
}

.heading-frame {
  padding: 38px 50px;
  border: 5px solid $heading-color;
  display: inline-block;
}

.intro-text {
  font-size: 17px;
  line-height: 32px;
}

/* Results
-------------------------------------------------------*/

.result-box {
  padding: 130px 0;
}

.result-box-first {
  background-color: #1b1b1b;
}

.result-box-second {
  background-color: #212121;
}

.result-box-third {
  background-color: #292929;
}

.result-wrap {
  width: 230px;
  margin: 0 auto;

  & i {
    font-size: 55px;
    color: #fff;
    float: left;
    margin-top: 3px;
  }
}

.statistic span {
  font-size: 36px;
  font-family: $heading-font;
  color: #fff;
  display: block;
  line-height: 1;
  margin-bottom: 9px;
  margin-left: 20px;
  float: left;

  &.counter-text {
    font-size: 13px;
    text-transform: uppercase;
    color: #929292;
    margin-bottom: 0;
  }
}

/* Services
-------------------------------------------------------*/

.service-item-box {
  //background-color: #fff;
  padding: 60px 40px;
  margin-bottom: 10px;

  & h3 {
    font-size: 15px;
    margin-bottom: 21px;
  }

  & > a {
    display: inline-block;
    margin-bottom: 27px;
  }

  & i {
    display: block;
    font-size: 24px;
    line-height: 70px;
    color: #fff;
  }
}

/*Hi Icons*/

.hi-icon {
  display: inline-block;
  font-size: 0px;
  cursor: pointer;
  width: 70px;
  height: 70px;
  @include border-radius (50%);
  text-align: center;
  position: relative;
  z-index: 1;
  color: #fff;

  &:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    @include border-radius (50%);
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

/* Effect 1 */
.icon-effect-1 .hi-icon {
  background-color: transparent;
  color: $main-color;
  border: 2px solid $main-color;
  @include transition (background 0.2s, color 0.2s);

  &:after {
    top: -5px;
    left: -5px;
    padding: 5px;
    @include box-shadow (0 0 0 2px #111);
    @include transition (transform 0.2s, opacity 0.2s);
    @include transform (scale(.8));
    opacity: 0;
  }

  &:hover {
    background: rgba(17, 17, 17, 1);
    color: #fff;
    border-color: transparent;
  }

  &:hover:after {
    @include transform (scale(1));
    opacity: 1;
  }
}

/* Promo Section
-------------------------------------------------------*/

.promo-section img {
  width: 100%;
}

.promo-description {
  padding: 50px 0 50px 50px;
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;

  & > h3 {
    margin-bottom: 22px;
  }

  & > p {
    color: #a6a6a6;
  }
}

.promo-section .customNavigation > a,
.from-blog .customNavigation > a {
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  font-size: 24px;

  &:first-child,
  &:first-child {
    margin-right: 10px;
  }

  &:hover,
  &:hover {
    opacity: .8;
  }
}

/* Portfolio
-------------------------------------------------------*/
.works-grid-3-col-wide .grayscale img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}

.work-item:hover img {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
}

.works-grid-3-col-wide .container-fluid {
  padding: 0 50px;
}

.grid-3-col.grid-gutter {
  margin: 0 0 -5px -5px;

  & .work-item {
    padding: 0 0 5px 5px;
    width: 33.333%;
    height: auto;
    float: left;
    overflow: hidden;
  }
}

.work-img {
  position: relative;
  overflow: hidden;

  & img {
    overflow: hidden;
    width: 100%;
    @include transition ($all);
  }

  & a.btn {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform (translate(-50%, -50%));
    height: auto;
    width: auto;
  }
}

.work-img > a,
.work-overlay {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.work-overlay {
  background-color: rgba(20, 20, 20, .7);
  border: 10px solid rgba(255, 255, 255, .2);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  @include transition ($all);
}

.work-description {
  position: absolute;
  display: block;
  left: 8%;
  top: 8%;
  width: 80%;
  @include transition (all .2s ease-in-out);

  & h3 {
    font-size: 16px;
    margin-bottom: 5px;
    color: #fff;
  }

  & a {
    color: #fff;
  }

  & span {
    font-size: 13px;
    color: #fff;
    display: inline-block;
  }
}

.work-description h3,
.work-description span {
  @include transform (translateX(-360px));
  @include transition (all .2s ease-in-out);
}

.work-item:hover img {
  @include transform (scale3d(1.1, 1.1, 1));
}

.work-item:hover .work-description h3,
.work-item:hover .work-description span {
  @include transform (translateX(0));
}

.work-item:hover .work-description span {
  @include transition-delay (.1s);
}

.work-item:hover .work-overlay {
  opacity: 1;
  z-index: 1;
}

/* Call to Action
-------------------------------------------------------*/

.call-to-action {
  padding: 70px 0;
  background-color: #fff;

  & h2 {
    font-size: 19px;
    margin-top: 15px;
  }
}

/* Process
-------------------------------------------------------*/

/*5 columns*/

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (min-width: 767px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}

.process-wrap i {
  font-size: 32px;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, .5);
  @include border-radius (50%);
  color: #fff;
}

.process-wrap h3 {
  color: #fff;
  font-size: 13px;
  margin: 30px 0 20px;
}

/* Our Team
-------------------------------------------------------*/

.our-team .team-row {
  margin-left: -40px;
  margin-right: -40px;
}

.our-team .team-wrap {
  padding: 0 40px;
}

.our-team .container-fluid {
  padding: 0 50px;
}

.team-img img {
  @include transition (all .2s ease-in-out);
  overflow: hidden;
  width: 100%;
}

.team-img:hover .team-details {
  opacity: 1;
  margin-top: -80px;
}

.team-img:hover .overlay {
  opacity: 1;
}

.team-member,
.team-img {
  position: relative;
  overflow: hidden;
}

.team-title {
  margin: 30px 0 7px;
}

.overlay {
  background-color: rgba(20, 20, 20, .7);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  @include transition (all .2s ease-in-out);
}

.team-details {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0;
  padding: 5%;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  @include transition (all .2s ease-in-out);

  & p {
    color: #fff;
  }

  & .socials i {
    color: #fff;
  }
}

.socials {

  & a {
    display: inline-block;
    width: 37px;
    height: 37px;
    background-color: transparent;

    &:hover i {
      color: #fff;
      background-color: $main-color;
    }
  }

  & i {
    line-height: 37px;
    color: #616161;
    font-size: 14px;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    text-align: center;
    @include transition (all .2s linear);
  }

}

/* About us
-------------------------------------------------------*/

.about-description h4,
.about-description p:first-of-type {
  margin-bottom: 15px;
}

/* Progress Bars
-------------------------------------------------------*/

#skills {
  margin-top: 35px;
}

.progress-bar {
  @include transition (width 1.5s ease-in-out);
  @include box-shadow (none);
}

.skills-progress h6,
.skills-progress h6 span {
  color: $heading-color;
  font-family: $heading-font;
  font-size: 13px;
  margin-top: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.progress-solid.meter {
  height: 8px;
  position: relative;
  background: #fbfbfb;
  margin-bottom: 30px;
}

.meter .progress-bar {
  display: block;
  height: 8px;
  position: relative;
  overflow: hidden;
  background-color: $main-color;
}

.skills-progress h6 span {
  float: right;
}

.row.mt-minus-30 {
  margin-top: -30px;
}

/* From Blog
-------------------------------------------------------*/

.blog-col {
  padding: 0 5px;

  & h4 {
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 4px;
    line-height: 1.5;
  }

  & h4 a {
    color: $heading-color;
  }
}

.from-blog .customNavigation > a {
  color: $heading-color;
}

.entry-box {
  padding: 40px;
  background-color: #fff;
}

.entry-meta {

  & li {
    display: inline-block;
    font-size: 13px;
    color: #919191;
    margin-bottom: 15px;
    white-space: nowrap;
  }

  & a {
    color: #919191;
  }

  & li:before {
    content: "|";
    opacity: 0.5;
    margin: 0 7px;
  }

  & li:first-child:before {
    content: "";
    margin: 0;
  }

}

.entry-meta a:hover,
.blog-col h4 a:hover {
  color: $heading-color;
}

.entry-img {
  position: relative;
  overflow: hidden;

  & img {
    @include transition (all 0.2s ease-out);
    width: 100%;
  }
}

.read-more {
  font-size: 12px;
  font-family: $heading-font;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: bold;
  color: $heading-color;

  &:hover {
    color: $heading-color;
  }
}

.blog-col-3 .entry-img:hover img {
  opacity: 0.8;
  @include transform (scale(1.1, 1.1));
}

.flex-direction-nav {
  a {
    display: block;
    font-size: 16px;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, .5);
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    text-align: center;
    color: #fff;
    line-height: 40px;
    @include transition($all);
  }

  & .flex-prev {
    left: -40px;
  }

  & .flex-next {
    right: -40px;
  }
}

.flexslider:hover .flex-direction-nav a {
  opacity: 1;
}

.flexslider:hover .flex-direction-nav .flex-next {
  right: 0;
}

.flexslider:hover .flex-direction-nav .flex-prev {
  left: 0;
}

.flexslider .flex-direction-nav .flex-nav-next:hover a,
.flexslider .flex-direction-nav .flex-nav-prev:hover a {
  color: #000;
  background-color: #fff;
}

/* Testimonials
-------------------------------------------------------*/

.testimonial-text {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.parallax-testimonials .bottom-line:after {
  border-color: #fff;
}

.testimonial-text {
  font-size: 24px;
  line-height: 39px;
  color: #fff;
  font-style: italic;
  margin: 30px 0;
  font-family: $body-font;
}

.testimonial {
  & i {
    font-size: 28px;
    color: #fff;
  }

  & span,
  & h6 {
    display: block;
    color: #fff;
  }

  & span {
    font-size: 13px;
  }

  & h6 {
    font-size: 13px;
    margin-bottom: 5px;
  }
}

.parallax-testimonials .owl-pagination {
  position: relative;
  margin-top: 50px;
}

.parallax-testimonials .owl-carousel {
  position: static;
}

/* Clients
-------------------------------------------------------*/

.client-logo {
  border-bottom: 1px solid #dedede;
  border-right: 1px solid #dedede;
  text-align: center;

  &:last-child {
    border-right: none;
  }
}

.second-row .client-logo {
  border-bottom: none;
}

/* Contact
-------------------------------------------------------*/

.contact-row {
  margin-left: -8px;
  margin-right: -8px;
}

.contact-name,
.contact-email {
  padding: 0 8px;
}

.contact-item {
  position: relative;
  padding-left: 70px;
  padding-top: 5px;
  margin-top: 30px;

  &:first-of-type {
    margin-top: 38px;
  }

  //& h6 {
  //  font-size: 12px;
  //  margin-top: 5px;
  //  margin-bottom: 7px;
  //}

  & p,
  & span,
  & a {
    font-size: 16px;
  }

  & a:hover {
    color: $heading-color;
  }
}

.contact-icon {
  width: 50px;
  height: 50px;
  @include border-radius (50%);
  border: 2px solid $main-color;
  text-align: center;
  position: absolute;
  left: 0;

  & i {
    font-size: 18px;
    color: $main-color;
    line-height: 47px;
  }
}

.gmap {
  position: relative;
  width: 100%;
  height: 450px;
}

#contact-form .message {
  height: 50px;
  width: 100%;
  font-size: 13px;
  line-height: 50px;
  text-align: center;
  float: none;
  margin-top: 20px;
  display: none;
  color: #fff;
}

#contact-form .message.error {
  background-color: #f44336;
}

#contact-form .message.success {
  background-color: #4CAF50;
}

/*-------------------------------------------------------*/
/* Page Title / Breadcrumbs
/*-------------------------------------------------------*/

.page-title {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-position: 50% 0;
  display: block;

  & .container {
    height: 600px;
  }

  & .heading-frame {
    border-color: #fff;
  }
}

.title-holder {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
}

.title-text {
  display: table-cell;
  height: 100%;
  vertical-align: middle;

  & h1 {
    margin: 30px 0;
    font-size: 32px;
  }
}

.page-title .breadcrumb {
  width: auto;
  background-color: transparent;
  padding: 0;
  margin: -10px 0 0 0;
  font-size: 13px;

  & a,
  & > .active {
    color: #fff;
  }

  & > li + li:before {
    color: #fff;
    opacity: 0.5;
  }
}

/*-------------------------------------------------------*/
/* Blog Standard
/*-------------------------------------------------------*/

.blog-content {

  & .entry-img img {
    @include transition (opacity .3s ease-in-out);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative;
  }

  & .entry-img a:hover img {
    opacity: .8;
  }

  & .entry-img,
  & .entry-slider,
  & .entry-video {
    position: relative;
    overflow: hidden;
    margin-bottom: 34px;
  }

  & .entry-meta li:first-child:before {
    margin: 0 0 0 -3px;
  }

  & .entry-title h2 > a {
    font-size: 21px;
    color: $heading-color;
  }

  & .entry-meta li {
    margin-bottom: 7px;
  }

  & .entry-content {
    margin-top: 17px;
  }
}

.blog-standard .sidebar,
.blog-single .sidebar {
  padding-left: 65px;
}

.entry-item {
  //margin-bottom: 60px;
  position: relative;
  overflow: hidden;
}

.blog-standard {

  & .entry {
    margin: 35px 0;
  }

  & .entry-title,
  & .entry-meta {
    padding-left: 93px;
    h2 {
      margin-bottom: 10px;
    }
  }

  & .entry-item > .entry-date {
    float: left;
    padding: 13px 19px;
    width: 70px;
    height: 70px;
    border: 3px solid $heading-color;
    font-family: $heading-font;
    color: #3b3b3b;
    font-weight: bold;
    text-align: center;
  }

  & .blog-content .entry-date span {
    display: block
  }

  & .blog-content .entry-date span:first-child {
    font-size: 21px;
    line-height: 1;
  }

  & .blog-content .entry-date span:last-child {
    font-size: 13px;
    text-transform: uppercase;
  }
}

.entry-content > i {
  margin-left: 3px;
  font-size: 14px;
  vertical-align: middle;
}

/* Quote Post
-------------------------------------------------------*/

.blog-content .entry.blockquote {
  padding-left: 0;
}

.blog-content .entry-item blockquote > p > a,
.blog-content .entry-item blockquote > p {
  color: $heading-color;
  font-size: 22px;
  line-height: 34px;
  font-style: italic;
  font-family: $body-font;
}

.blog-content .entry-item blockquote > p > a:hover {
  color: $text-color;
}

.blockquote-style-1 > i {
  font-size: 22px;
  color: #d1d1d1;
  margin-bottom: 13px;
}

/* Video Post
-------------------------------------------------------*/

.entry-video iframe {
  width: 100%;
  display: block;
  border: 0;
}

/* Pagination
-------------------------------------------------------*/

.pagination {
  margin: 0 auto;
  border-radius: 0;
  display: block;
  text-align: center;
  padding-top: 42px;
  border-top: 1px solid $border-color;

  & a,
  & span {
    background-color: #fff;
    font-size: 12px;
    display: inline-block;
    height: 25px;
    line-height: 20px;
    text-align: center;
    margin: 0 9px;
    @include transition($all);
    font-family: $heading-font;
    font-weight: bold;
    text-transform: uppercase;
  }

  & span.pagination-dots {
    margin: 0 3px;
  }

  & i:first-child {
    margin-right: -5px;
  }

  & i:last-child {
    margin-left: -7px;
  }

  & a {
    color: #757575;
  }

  & a:hover {
    color: $heading-color;
  }

  & a > i {
    font-size: 10px;
    margin: 0 3px;
  }

  & .current {
    color: #3b3b3b;
  }

  & > i {
    font-size: 14px;
    vertical-align: middle;
  }
}

/*-------------------------------------------------------*/
/* Blog Sidebar
/*-------------------------------------------------------*/

.sidebar {

  & .widget {
    margin-top: 60px;
    position: relative;
  }

  & .widget:first-child {
    margin-top: 0;
  }

  & .widget ul li {
    padding: 10px 0;
    border-bottom: 1px solid $border-color;
  }

  & .widget ul li:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  & .widget ul li:first-child {
    padding-top: 0;
  }

  & .widget ul li a {
    color: $text-color;
  }

  & .widget ul li a:hover {
    color: $heading-color;
  }

}

.widget-title {
  font-size: 15px;
  margin-bottom: 28px;
}

.blog-sidebar .searchbox {
  border: 2px solid $border-color;
  margin-bottom: 0;
  padding: 0 20px;
}

input[type="search"] {
  color: $heading-color;
}

input.searchbox:focus {
  border-color: $main-color;
  color: $heading-color;
}

.search-button {
  position: absolute;
  top: 12px;
  right: 15px;
  background-color: transparent;
  border: none;

  & i {
    color: #a1a1a1;
  }
}

/* Latest Posts
-------------------------------------------------------*/

.blog-sidebar {

  & .widget.latest-posts ul li {
    border-bottom: none;
    line-height: 19px;
  }

  & .latest-posts img {
    margin-right: 24px;
    float: left;
  }

  & .latest-posts li {
    padding: 17px 0 !important;
  }

  & .latest-posts li:first-child {
    padding-top: 0 !important;
  }

  & .latest-posts li:last-child {
    padding-bottom: 0 !important;
  }
}

.widget {

  &.latest-posts ul li a {
    font-size: 11px;
    font-family: $heading-font;
    text-transform: uppercase;
    color: $heading-color;
    vertical-align: top;
    line-height: 1;
  }

  &.latest-posts .entry-meta > span {
    color: $text-color;
    font-size: 13px;
    font-family: $body-font;
    text-transform: none;
    margin-top: 5px;
    display: block;
  }

  &.latest-posts ul li a:hover {
    color: $heading-color;
  }
}

/* Popular Tags
-------------------------------------------------------*/

.tags a {
  background-color: transparent;
  border: 3px solid $border-color;
  font-family: $heading-font;
  text-transform: uppercase;
  padding: 9px 16px;
  line-height: 1;
  margin: 0 8px 8px 0;
  font-size: 10px;
  color: #ababab;
  display: inline-block;
  float: left;
  @include transition ($all);

  &:hover {
    border-color: $heading-color;
    color: #000;
  }
}

/*-------------------------------------------------------*/
/* Blog Single Post
/*-------------------------------------------------------*/

.blog-single {

  & .entry-item {
    margin-bottom: 0;
  }

  & .entry-title {
    margin-top: 70px;
    text-align: center;
  }

  & .entry-meta {
    text-align: center;
  }

  & .blog-content .entry-title h2 {
    font-size: 28px;
  }

  & .blog-content .entry-content {
    margin-top: 50px;
  }

  & .blog-content blockquote {
    margin: 60px 0;
  }

  & .blog-content .entry-content > p {
    margin-bottom: 24px;
  }
}

.entry-content .entry-tags h6,
.entry-share h6 {
  display: inline-block;
  margin-right: 2px;
  font-size: 14px;
}

.entry-content {

  & .entry-tags h6 {
    margin-right: 7px;
  }

  & .entry-tags a {
    font-style: italic;
    font-size: 14px;
    line-height: 37px;
    font-family: $body-font;
    color: $text-color;

    &:hover {
      color: $heading-color;
    }
  }

  & .entry-share {
    float: right;
  }
}

.entry-share {

  & .socials i,
  & .socials a {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }

  & .socials {
    margin-top: 0;
    display: inline-block;
  }
}

.entry-tags h6,
.entry-share h6 {
  font-size: 15px;
  text-transform: none;
  letter-spacing: normal;
}

.entry-comments {

  & .comment-avatar {
    position: absolute;
    display: block;
  }

  & .comment-content {
    padding-left: 132px;
  }

  & .comment-author {
    display: block;
    margin-bottom: 5px;
    font-family: $heading-font;
    font-weight: bold;
    font-size: 15px;
    color: $heading-color;
  }

  & h6 {
    font-size: 14px;
  }

  & .comment-content span a {
    font-size: 13px;
    color: $text-color;
  }
}

.comment-content p {
  margin-top: 15px;
}

.comment-content > a {
  font-family: $heading-font;
  color: $heading-color;
  font-size: 14px;
}

.comment-reply {
  padding-left: 48px;
}

.comment-body {
  padding: 35px 0;
  border-bottom: 1px solid #dbdbdb;
}

.comment-list > li:last-child .comment-body {
  border-bottom: none;
  padding-bottom: 0;
}


/*-------------------------------------------------------*/
/* Home Text Rotator
/*-------------------------------------------------------*/

.hero-message.text-rotator h1 {
  font-size: 70px;
}

/*-------------------------------------------------------*/
/* Home Video Bg
/*-------------------------------------------------------*/

.video-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
}

.video-overlay {
  background-color: rgba(40, 40, 40, 0.5);
  height: 100%;
  width: 100%;
}

.video-img {
  background: url(../video/video.jpg) center center no-repeat;
  background-size: cover;
  z-index: -101;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/*-------------------------------------------------------*/
/* Home Angles
/*-------------------------------------------------------*/

.main-wrapper-onepage.angles .result-box {
  padding: 30% 0;
}

.section-wrap.angle-top,
.section-wrap.angle-bottom {
  overflow: visible;
}

.bg-light.angle-top:before,
.bg-light.angle-bottom:after {
  background-color: $bg-light;
}

.bg-dark.angle-top:before,
.bg-dark.angle-bottom:after {
  background-color: #242424;
}

.angle-top:before {
  background: none repeat scroll 0 0 #fff;
  content: "";
  margin-top: -199px;
  min-height: 150px;
  position: absolute;
  @include transform (skewY(-2deg));
  width: 100%;
  z-index: 1;
}

.angle-bottom:after {
  background: none repeat scroll 0 0 #fff;
  content: "";
  margin-top: 40px;
  min-height: 150px;
  position: absolute;
  @include transform (skewY(-2deg));
  width: 100%;
  z-index: 1;
}

#portfolio.angle-bottom:after {
  margin-top: 140px;
}

.main-wrapper-onepage.angles .process,
.main-wrapper-onepage.angles .parallax-testimonials {
  padding: 200px 0;
}

.main-wrapper-onepage.angles .footer.minimal {
  text-align: left;
}

.main-wrapper-onepage.angles .gmap {
  height: 600px;
}

/* Services
-------------------------------------------------------*/

.services {
  padding: 70px 0 30px 0;

  & a {
    position: absolute;
    font-size: 36px;
    line-height: 1;
    color: $main-color;
  }

  & .service-item-box {
    padding: 0 0 0 60px;
    margin-bottom: 40px;
  }

  & .service-item-box h3 {
    margin-bottom: 10px;
  }

}

/* Section - content
-------------------------------------------------------*/

.section-content {
  @media (max-width: 480px) {
    .image-col {
      margin-bottom: 20px;
    }
  }


}

/* Latest Works
-------------------------------------------------------*/

.portfolio-filter {
  list-style: none;
  margin-bottom: 40px;
  cursor: default;
  font-size: 13px;
  text-align: center;

  & a.active,
  & a:hover {
    color: $heading-color;
    border-color: $heading-color;
  }

  & a {
    display: inline-block;
    margin: 0 5px 5px 0;
    color: #ababab;
    text-decoration: none;
    padding: 7px 15px;
    border: 3px solid $border-color;
    font-family: $heading-font;
    text-transform: uppercase;
    font-size: 12px;
    @include transition ($all);
  }
}

.work-container {
  margin-bottom: 40px;
}

.work-item .work-overlay:hover,
.work-item:hover .portfolio-overlay {
  opacity: 1;
  z-index: 1;
}

.portfolio-overlay {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  @include transition ($all);
}

.project-icons {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  margin-top: -20px;
  @include transition ($all);

  & a {
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 14px;
    margin: 0 3px;
    line-height: 40px;
    text-align: center;
    color: $heading-color;
    background-color: #fff;
    border-radius: 100px;
    @include transition ($all);

    &:hover {
      color: #fff;
      background-color: $main-color;
    }
  }
}

.work-item:hover .portfolio-overlay .project-icons {
  position: absolute;
  top: 50%;
}

.works-grid.titles .work-description h3,
.works-grid.titles .work-description span,
#owl-related-works .work-description h3,
#owl-related-works .work-description span {
  @include transform (none);
}

.works-grid.titles .work-description,
#owl-related-works .work-description {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.works-grid.titles .work-description a,
#owl-related-works .work-description a {
  color: $heading-color;

  &:hover {
    color: $main-color;
  }
}

/* Call to Action Style-2
-------------------------------------------------------*/

.call-to-action.style-2 {
  padding: 200px 0;

  & h2 {
    font-size: 32px;
    color: #fff;
    margin: 0 0 25px;
  }

  & a {
    margin: 10px 4px 0;
  }
}

/* Ipad with Features
-------------------------------------------------------*/

.promo-device img {
  display: inline-block;
}

.features-icons a {
  position: absolute;
}

.features-text {
  padding-left: 100px;
}

.features-icons .service-item-box {
  padding: 0;
  margin-bottom: 50px;
  background-color: transparent;
}

/* Pricing Tables
-------------------------------------------------------*/
.pricing-3-col {
  background-color: $bg-light;
  text-align: center;
}

.pricing-title {
  background-color: #303030;
  padding: 45px 30px;

  & h3 {
    font-size: 18px;
    color: #fff;
    margin-bottom: 30px;
  }
}

.best-price {
  background-color: $main-color;
}

.pricing-price {
  font-size: 25px;
  color: $heading-color;
  background-color: #fff;
  @include border-radius (50%);
  width: 110px;
  height: 110px;
  display: inline-block;
  position: absolute;
  left: 50%;
  margin-left: -55px;
  line-height: 1.3;
  padding: 28px 25px;
  @include box-shadow (0px 0px 5px 0px rgba(14, 16, 9, .10));
}

.pricing-currency {
  font-family: $heading-font;
}

.pricing-term {
  font-size: 12px;
  display: block;
}

.pricing-features {
  padding: 100px 40px 40px;
  color: $text-color;

  & li {
    padding: 10px 0;
  }
}

.pricing-button {
  padding: 0 40px 50px 40px;
}

/* Fun Facts style-2
-------------------------------------------------------*/

.result-boxes.style-2 {

  & .result-box {
    padding: 20px 0;
    text-align: center;
  }

  & .statistic span.counter-text {
    color: #fff;
    font-size: 13px;
  }

  & .statistic span {
    float: none;
    margin-left: 0;
    line-height: 1.2;
    font-size: 50px;
  }

  & .result-wrap {
    width: auto;
  }
}

/* Testimonials style-2
-------------------------------------------------------*/

.testimonials.style-2 {

  & .testimonial-text {
    color: $text-color;
  }

  & .owl-carousel {
    position: static;
  }

  & .owl-pagination {
    position: static;
    margin-top: 30px;
  }

  & .owl-page span {
    border-color: #a7a7a7;
  }

  & .owl-page.active span {
    background-color: $text-color;
    border-color: $text-color;
  }

  & .testimonial span {
    color: #ababab;
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
  }

  & .testimonial-text {
    margin: 10px 0 20px;
    font-family: $alt-font;
    font-size: 20px;
  }

}

/* Page Title Style-2
-------------------------------------------------------*/

.page-title.style-2 {
  background-color: $bg-light;
  padding: 60px 0;

  & .container {
    height: auto;
  }

  & .title-text h1 {
    margin: 0;
    font-size: 28px;
  }

  & .breadcrumb {
    position: absolute;
    right: 0;
    width: auto;
    background-color: transparent;
    top: 50%;
    padding: 0;
  }

  & .breadcrumb a,
  & .breadcrumb > li + li:before {
    color: $text-color;
  }

  & .breadcrumb > .active,
  & .breadcrumb a:hover {
    color: $heading-color;
  }
}

/*-------------------------------------------------------*/
/* Portfolio
/*-------------------------------------------------------*/

.works-grid.titles h3,
#owl-related-works h3 {
  font-size: 14px;
}

.call-to-action.bg-light {
  background-color: $bg-light;
}

.portfolio-description {
  padding-left: 10%;

  & p,
  & ul,
  & a.btn {
    margin-bottom: 30px;
  }

  & h2 {
    font-size: 22px;
    margin-bottom: 15px;
  }

  & ul > li {
    line-height: 30px;
    color: $heading-color;
  }

  & ul > li a {
    color: $text-color;
  }

  & ul > li a:hover {
    color: $main-color;
  }
}

.related-projects {

  & .work-container {
    margin-bottom: 0;
  }

  & .heading-inline,
  & .customNavigation {
    display: inline-block;
  }

  & .customNavigation a {
    background-color: #f2f2f2;
    color: $heading-color;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 0;
    line-height: 30px;
    border: none;
  }

  & .customNavigation i {
    font-size: 14px;
    line-height: 30px;
  }

  & .customNavigation a:hover {
    background-color: $main-color;
    color: #fff;
  }
}

#owl-related-works .owl-item .work-item {
  margin: auto 15px;
}

/*-------------------------------------------------------*/
/* 404
/*-------------------------------------------------------*/

.page-404 {

  & h1 {
    font-size: 200px;
  }

  & a,
  & p {
    font-size: 14px;
  }
}

/* Intro
-------------------------------------------------------*/

.section-wrap.intro {
  padding: 80px 0 60px;
  &:post {
    content: '';
    display: block;
    width: 150px;
    height: 10px;
    margin: 0 auto;
    background-color: $light-background;
  }
}

.intro-text p {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.5;
  font-family: $heading-font;
  color: $heading-color;
}

/* Portfolio Masonry
-------------------------------------------------------*/

.masonry-grid {
  width: 100%;

  & .work-item {
    width: 50%;
    float: left;
  }

  & .work-item.quarter {
    width: 25%;
  }

  & .work-description {
    width: 100%;
    text-align: center;
    margin-top: -24px;
    top: 50%;
    left: 0;
    padding: 0 15px;
  }

  & .work-img img {
    @include transition (all 1.5s linear);
  }

  & .work-description h3,
  & .work-description span {
    @include transform (translate(0));
  }

  & .work-description h3 {
    font-size: 20px;
  }

  & .work-overlay {
    border: none;
  }
}

/*-------------------------------------------------------*/
/* Portfolio About Me
/*-------------------------------------------------------*/

.about-me .my-photo {
  width: 100%;
}

.about-me .info {
  padding-left: 7%;
}

/*-------------------------------------------------------*/
/* Youtube Video bg
/*-------------------------------------------------------*/

#ytb-wrap {
  background-size: cover;

  & .hero-text {
    color: #fff;
    text-shadow: none;
    font-weight: 700;
    line-height: 1;
    font-family: $heading-font;
    letter-spacing: 0.02em;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    font-size: 100px;
    text-transform: none;
  }
}

/*-------------------------------------------------------*/
/* Typography
/*-------------------------------------------------------*/

.section-headings p {
  margin-bottom: 30px;
}

blockquote {
  padding: 0 0 20px;
  margin: 0;
  border: none;
}

blockquote > p {
  font-size: 18px;
  line-height: 30px;
  font-style: italic;
  margin-bottom: 5px !important;
  position: relative;
}

blockquote > span {
  font-size: 14px;
}

.blockquote-style-1 {
  padding: 8% 10%;
  border: 4px solid #e5e5e5;
}

.blockquote-style-2 {
  padding: 20px 40px;
  border-left: 3px solid #333333;
}

.dropcap.style-1 {
  float: left;
  color: #333;
  font-size: 52px;
  line-height: 46px;
  padding-top: 4px;
  padding-right: 10px;
}

.dropcap.style-2 {
  float: left;
  color: #fff;
  text-align: center;
  background-color: #333333;
  width: 38px;
  height: 38px;
  font-size: 24px;
  line-height: 38px;
  margin: 10px 10px 0 0;
}

.highlight {
  padding: 3px 5px;
  color: #fff;
  background-color: #bfa67a;
}

.bullets li,
.arrows li,
.arrows-2 li,
.numbers li {
  margin-bottom: 10px;
}

.bullets {
  list-style-type: disc;
  margin-left: 17px;
}

.bullets li:before {
  font-size: 18px;
  color: #333333;
  padding-right: 0.5em;
  line-height: 1;
  vertical-align: middle;
}

.arrows i {
  margin-right: 5px;
}

.arrows i,
.arrows-2 i {
  color: #333333;
}

.arrows-2 i {
  font-size: 12px;
  margin-right: 7px;
}

ol.numbers {
  padding-left: 20px;
}

.section-columns p {
  margin-bottom: 40px;
}
