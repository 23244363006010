.navbar-nav .nav-item {
  float: none;

}
@include media-breakpoint-up(xs) {
  #menu-main-menu {
    background-color:  $heading-color;
  }
}
@include media-breakpoint-up(sm) {
  #menu-main-menu {
    background-color:  $main-color;
    margin-bottom: 30px;
  }
}


@include media-breakpoint-up(lg) {
  #menu-main-menu {
    background-color: transparent;
  }

  .nav-type-2 {
    margin: 20px 0 0;
  }
  .collapse {
    display: block;
  }
  .navbar-nav .nav-item {
    float: left;

  }
  .navbar-toggler {
    display: none;
  }
  .navbar-nav > li > a:hover,
  .navbar-nav > .active > a,
  .navbar-nav > .active > a:focus,
  .navbar-nav > .active > a:hover,
  .menu-socials li > a:hover {
    &:after {
      content: '';

      width: 100%;
      position: absolute;
      left: 0;
      bottom: 25px;

      border-width: 0 0 3px;
      border-style: solid;
      border-color: $white-alpha;
    }
  }

}
  /*-------------------------------------------------------*/
/* Navigation Onepage
/*-------------------------------------------------------*/

.navbar {
  padding: 0;
  margin: 20px 0 0 0;
  border: none;
  min-height: 60px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  @include transition ($all);
  z-index: 1200;
  .navbar-toggler {
    padding: 20px;
    font-size: 20px;
    color: white;
  }
}
.navbar-nav .nav-link {
  position: relative;
}
.navbar-nav .nav-item+.nav-item, .navbar-nav .nav-link+.nav-link {
  margin-left: 0;
}
.navbar-nav {
  margin: 0;
  float: none;
  display: inline-block;

  & > li > a {
    font-family: $body-font;
    text-transform: normal;
    padding: 0 20px;
    line-height: 90px;
    color: #fff;
    //font-size: 13px;
    //letter-spacing: 0.02em;
    //font-weight: bold;
    @include transition ($all);
  }
}

.navigation-overlay {
  background-color: rgba(17,17,17,.5);
  width: 100%;
  line-height: 0;
  @include transition ($all);

  &.sticky {
    background-color: rgba(17,17,17,1);
    visibility: visible;
    opacity: 1;
  }

  &.sticky .navbar-nav > li > a,
  &.sticky .menu-socials li > a {
    line-height: 60px;
  }
}

.navbar-header {
  width: 20%;
  padding-right: 15px;
}

.nav-wrap {
  width: 60%;
}

.menu-socials {
  width: 20%;
  float: right;
  padding: 0 15px;

  & ul {
    float: right;
  }

  & li {
    display: inline-block;
  }

  & li > a {
    font-size: 16px;
    line-height: 90px;
    margin-left: 22px;
    color: #fff;
    border-bottom: 1px solid #fff;
    @include transition ($all);
  }

  & li > a:hover {
    border-color: $main-color;
  }
}


.logo-container {
  padding: 0 15px;
  float: left;
}

.logo-wrap {
  display: table;
  width: 100%;

  & > a {
    display: table-cell;
    vertical-align: middle;
    height: 90px;
    @include transition ($all);
  }

  &.shrink > a {
    height: 60px;
  }

  & img {
    @include transition ($all);
    height: auto;
  }

  &.shrink img {
    height: 48px;
  }
}

.navbar-collapse.in {
  overflow-x: hidden;

}

.navbar-nav > li > a:hover,
.navbar-nav > .active > a,
.navbar-nav > .active > a:focus,
.navbar-nav > .active > a:hover,
.menu-socials li > a:hover {
  opacity: 1;
  color: white !important;
  //text-decoration: underline;

}

.navbar-collapse {
  padding: 0;
  border-top: none;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover,
.nav > li > a:focus,
.nav > li > a:hover {
  background-color: transparent;
  text-decoration: none;
  border-color: #f2f2f2;
}


@include media-breakpoint-down(md) {

  .navigation-overlay,
  .navigation-overlay.sticky {
    background-color: rgba(17,17,17,.9);
  }

  .nav-wrap {
    width: 100%;
    padding: 0;
    min-height: 0;
  }

  .nav-type-1 .container-fluid {
    padding: 0 15px;
  }

  .navbar-header {
    width: 100%;
    padding-right: 0;
  }

  .logo-wrap img {
    height: 58px;
  }

  .logo-wrap > a {
    height: 70px;
  }

  .navbar-nav {
    width: 100%;
    padding: 40px 0;
  }

  .navigation-overlay.sticky .navbar-nav > li > a,
  .navigation-overlay .navbar-nav > li > a {
    padding: 10px 0;
    line-height: 30px;
  }

}


/* Navigation Toggle
-------------------------------------------------------*/

.navbar-toggle {
  margin-top: 13px;
  border: none;
  z-index: 50;
  margin-right: 5px;

  & .icon-bar {
    background-color: #fff;
    width: 18px;
  }

  &:focus,
  &:hover {
    background-color: transparent;
  }

  &:focus .icon-bar,
  &:hover .icon-bar {
    background-color: #f2f2f2;
  }
  @media (min-width: 1201px) {
    display: none;
  }
}


/*Go to Top*/

#back-to-top {
  display: block;
  z-index: 500;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 22px;
  position: fixed;
  bottom: -40px;
  right: 20px;
  line-height: 35px;
  @include border-radius (50%);
  @include transition (all 1s ease-in-out);
  background-color: #fff;
  text-decoration: none;
  box-shadow: 1px 1.732px 12px 0px rgba( 0, 0, 0, .14 ), 1px 1.732px 3px 0px rgba( 0, 0, 0, .12 );

  & i {
    @include transition (all 1s ease-in-out);
  }

  & a {
    width: 40px;
    height: 40px;
    display: block;
    color: $heading-color;
  }

  &.show {
    bottom: 20px;
  }

  &:hover {
    background-color: $heading-color;
  }

  &:hover i {
    color: #fff;
  }
}


/*-------------------------------------------------------*/
/* Navigation Multi-Page
/*-------------------------------------------------------*/

.nav-type-2 {
  position: absolute;
  min-height: 60px;
  margin: 0 0 0;
  width: 100%;
  z-index: 1200;

  .navbar-fixed-top {

    .navigation {
      a {
        color: #ffffff;
        @include transition (color .3s ease-in-out);
      }
      &.sticky {
        a {
          color: white;
        }
      }
    }
  }
  & .navbar {
    min-height: 90px;
  }

  & .nav-wrap {
    width: 80%;
  }

  & .navbar-nav > li > a {
    padding: 0 10px;
  }

  & .navbar-toggle:focus .icon-bar,
  & .navbar-toggle:hover .icon-bar {
    background-color: $heading-color;
  }

  & .navbar-nav > li > a {
    //color: $heading-color;
    @include transition (color .3s ease-in-out);
  }
}

.nav-type-2 .navbar-toggle .icon-bar,
.nav-type-4 .navbar-toggle .icon-bar {
  background-color: white;
}

.navigation {
  &.offset {
    @include transform (translate3d(0, -300px, 0));
    @include transition ($all);
  }

  &.sticky {
    position: fixed;
    background-color:  $main-color;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 60px;
    top: 0;
    box-shadow: 0 0 5px rgba(0,0,0,.1);
    z-index: 1009;
    a {
      color: #ffffff;

    }
  }

  &.scrolling {
    @include transform (translate3d(0, 0px, 0));
  }

  &.sticky.scrolling {
    opacity: 1;
    visibility: visible;
  }

  &.sticky .navbar-nav > li > a {
    line-height: 60px;
  }
}


.navbar-nav .dropdown-menu {
  left: 0;

  &.menu-right {
    left: auto;
    right: 0;
  }
}

.dropdown-menu {
  min-width: 200px;
  margin: 0;
  padding: 10px 0;
  border-top: 3px solid $main-color;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  border-bottom: none;
  @include border-radius(0);
  @include box-shadow (0 2px 4px rgba(0,0,0,.05));

  & > li > a {
    padding: 10px 20px;
    font-size: 13px;
    color: $text-color;
    @include transition ($all);
    font-weight: 500;
  }

  & > li > a:focus,
  & > li > a:hover {
    background-color: transparent;
    color: $main-color;
  }

  & .megamenu-wrap ul > li > a {
    display: block;
    clear: both;
    font-weight: 500;
    line-height: 1.42857143;
    color: $text-color;
    white-space: nowrap;
    @include transition ($all);
  }
}

.dropdown-submenu > a:after {
  font-family: "FontAwesome";
  position: absolute;
  content: "\f105";
  right: 15px;
  color: $text-color;
}

.dropdown-menu,
.dropdown-submenu > .dropdown-menu {
  display: block;
  visibility: hidden;
  opacity: 0;
  @include transition (all .1s ease-in-out);
}

.dropdown:hover > .dropdown-menu,
.dropdown-submenu:hover > .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.navbar .dropdown-menu {
  margin-top: 0;
}

.dropdown-submenu {
  position: relative;

  & .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -2px;
  }
}



/* Nav icon */

#nav-icon {
  right: 40px;
  top: 50%;
  margin-top: -7px;
  position: absolute;
  margin-right: 0;
  z-index: 300;
  cursor: pointer;
  width: 20px;
  height: 20px;
  @include transform (rotate(0deg));
  @include transition (.2s ease-in-out);
  cursor: pointer;

  & span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $heading-color;
    opacity: 1;
    left: 0;
    @include transform (rotate(0deg));
    @include transition (.25s ease-in-out);
  }

  & span:nth-child(1) {
    top: 0px;
  }

  & span:nth-child(2),
  & span:nth-child(3) {
    top: 6px;
  }

  & span:nth-child(4) {
    top: 12px;
  }

  &.open span:nth-child(1) {
    top: 6px;
    width: 0%;
    left: 50%;
  }

  &.open span:nth-child(2) {
    @include transform (rotate(45deg));
    background: #fff;
  }

  &.open span:nth-child(3) {
    @include transform (rotate(-45deg));
    background: #fff;
  }

  &.open span:nth-child(4) {
    top: 6px;
    width: 0%;
    left: 50%;
  }
}

