// Grid settings
$enable-flex:           false;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$text-color: #7a7a7a;
$heading-color: #111;
$bg-light: #f7f7f7;
$bg-dark: #242424;
$border-color: #e5e5e5;
$main-color: #0091b5;
$color-blue-light: #5acdd0;
$color-blue-dark:#253796;
$white-alpha: rgba(255, 255, 255, 0.4);
//coloured background
$light-background: #ebeff2;
.light-background{
  background-color: $light-background;
}

// Fonts
$body-font: 'Raleway', sans-serif;
$heading-font: 'Source Sans Pro', sans-serif;
$alt-font: 'Open Sans', sans-serif;
$body-font-size: 15px;

$font-url--google       : 'https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600italic,600,700,800,700italic,800italic|Roboto+Slab:400,100,300,700';

$font-family-primary    : 'Raleway', sans-serif;
$font-family-secondary  : 'Raleway', sans-serif;
//'Raleway', sans-serif;
//'Source Sans Pro', sans-serif;