/*-------------------------------------------------------*/
/* General
/*-------------------------------------------------------*/

.clearfix {
  *zoom: 1;
}

.clearfix:before, .clearfix:after {
  display: table;
  line-height: 0;
  content: "";
}

.clearfix:after {
  clear: both;
}

.clear {
  clear: both;
}

.oh {
  overflow: hidden;
}

.relative {
  position: relative;
}

.section-wrap, .section-wrap-mp {
  padding: 40px 0;
  overflow: hidden;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.section-wrap-mp {
  padding: 40px 0;
}

.color-white {
  color: #fff !important;
}

.left {
  float: left;
}

.right {
  float: right;
}

.bg-light {
  background-color: $bg-light;
}

.bg-dark {
  background-color: $bg-dark;
}

.last {
  margin-bottom: 0 !important;
}

::-moz-selection {
  color: #fff;
  background: #000;
}

::-webkit-selection {
  color: #fff;
  background: #000;
}

::selection {
  color: #fff;
  background: #000;
}

a {
  text-decoration: none;
  color: $main-color;
  outline: none;
  @include transition (color .2s ease-in-out);

  &:hover,
  &:focus {
    text-decoration: none;
    color: $text-color;
    outline: none;
  }
}

:focus {
  outline: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  .entry-item & {
    list-style: disc;
   // margin: 1em;

  }
}

body {
  margin: 0;
  padding: 0;
  font-family: $body-font;
  color: $text-color;
  font-size: 16px;
  line-height: 1.8;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  letter-spacing: 0.05em;
}

video {
  height: 100%;
  width: 100%;
}

body img {
  border: none;
  max-width: 100%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  color: $heading-color;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 0.3em;

}

h1 {
  font-family: $heading-font;

  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;

}

h2 {
  font-family: $heading-font;

  font-size: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  //color: $main-color;
  text-transform: none;
  font-weight: 100;
}

h3 {
  font-family: $heading-font;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;

}

h4 {
  font-family: $body-font;

  font-size: 1.4rem;
  text-transform: none;
  //letter-spacing: 0.05em;
  color: $main-color;
  font-weight: 400;
}

h5 {
  font-family: $body-font;

  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;

}

h6 {
  font-family: $body-font;

  text-transform: uppercase;
  letter-spacing: 0.05em;
}




p {
  // font-size: 15px;
  color: $text-color;
  font-weight: normal;
  // line-height: 25px;
}

.subheading {
  font-family: $body-font;
  font-size: 16px;
  font-style: italic;
}

.heading {
  margin-bottom: 60px;
}

.bottom-line:after {
  content: "";
  display: block;
  width: 48px;
  border-bottom: 3px solid #e8e8e8;
  margin: 15px auto 15px;
}

/*-------------------------------------------------------*/
/* Preloader
/*-------------------------------------------------------*/

.loader-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 5px;
  width: 5em;
  height: 5em;
  margin: -25px 0 0 -25px;
  text-indent: -9999em;
  border-top: 0.5em solid rgba(18, 18, 18, 1);
  border-right: 0.5em solid rgba(245, 245, 245, .5);
  border-bottom: 0.5em solid rgba(245, 245, 245, .5);
  border-left: 0.5em solid rgba(245, 245, 245, .5);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.loader,
.loader:after {
  @include border-radius (50%);
  width: 10em;
  height: 10em;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}