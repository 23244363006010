.header-section {
  margin-bottom: 20px;
  .item {
    position: relative;
    .background-image {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      display: block;
      overflow: hidden;
      background-attachment: scroll;
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

    }
    .color-overlay {
      opacity: 0.8;
      background: -webkit-linear-gradient(to right, $color-blue-light, $color-blue-dark); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, $color-blue-light, $color-blue-dark); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;

    }
    .hero-wrap {
      // -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      ///filter: grayscale(100%);
      z-index: 20;
      &:before {
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  }
}


/* Owl Carousel
-------------------------------------------------------*/

.owl-pagination {
  position: absolute;
  left: 0;
  display: block;
  text-align: center;
  width: 100%;
}

.owl-buttons {
  position: static;
  div {
    color: #fff;
    display: inline-block;
    zoom: 1;
    margin: 5px;
    //padding: 3px 10px;
    font-size: 22px;
    border-radius: 0px;
    background: $main-color;
    filter: Alpha(Opacity=50);
    opacity: .5;
    font-style: normal;
    font-weight: normal;
    speak: none;
    text-decoration: inherit;
    text-align: center;

  }
}

.owl-prev, .owl-next {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -23px;
  text-align: center;
  line-height: 46px;
  z-index: 10;
  width: 46px;
  height: 46px;
  background-color: #fff;
  border-radius: 0;
  opacity: 0;
  @include transition ($all);

  &:hover i {
    color: $main-color;
  }
}

.owl-prev {
  left: -40px;
}

.owl-next {
  right: -40px;
}

#owl-slider-one-img:hover .owl-prev,
#owl-slider-small-img:hover .owl-prev {
  opacity: 1;
  left: 0;
}

#owl-slider-one-img:hover .owl-next,
#owl-slider-small-img:hover .owl-next {
  opacity: 1;
  right: 0;
}

.owl-page {
  display: inline-block;
  padding: 6px 6px;
  position: relative;

  & span {
    display: block;
    position: relative;
    width: 10px;
    height: 10px;
    opacity: 0.8;
    @include border-radius (20px);
    background: transparent;
    z-index: 100;
    border: 2px solid #fff;
    @include transition (all .2s ease-in-out);
  }

  & span:hover {
    opacity: 1;
  }
}

.owl-theme .owl-controls .owl-page.active span {
  display: block;
  width: 10px;
  height: 10px;
  margin: 0;
  opacity: 1;
  background: #fff;
}
.container-fixed-height {
  height: 500px;
}

/*-------------------------------------------------------*/
/* Home Owl Slider
/*-------------------------------------------------------*/

.hero-wrap {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  background-attachment: scroll;
  @include background-img-cover;
}

.hero-holder {
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
}

.hero-message {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  text-align: center;

  & h1 {
    color: #fff;
    font-size: 31px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom:0;
    letter-spacing:0.2em;
  }
}

.hero-subtitle {
  text-transform: none;
  font-weight: normal;
  color: #fff;
  line-height: 1.5;
  font-size: 20px;
  max-width: 800px;
  margin: 24px auto 0;
}

.buttons-holder > a {
  margin: 5px;
}
